import {
  Box,
  FormControlLabel,
  Grid,
  InputLabel,
  Switch,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  Stripe,
  StripeCardElementOptions,
  StripeElementChangeEvent,
  StripeElementType,
} from "@stripe/stripe-js";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import React, { FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { CancelOutlined } from "@mui/icons-material";
import StripeWalletPayments from "components/Quote/sections/payment/StripeWalletPayments";
import AnimatedButton from "components/custom/AnimatedButton";
import { COUNTRY } from "config";
import { setLoaderText } from "store/features/loaderSlice";
import { setLoadingValue } from "store/features/paymentSlice";
import {
  customStylesSelector,
  paymentSelector,
} from "store/selectors/selectors";
import { RootState } from "store/store";
import { useT } from "translation";
import PaymentFooter from "./PaymentFooter";
import { toggleAutoRenewalConsent } from "store/features/clientSlice";

export type StripeCheckoutFormProps = {
  customerEmail?: string;
  onCancel: () => void;
  onSubmit: (details: {
    paymentMethodId: string;
    autoRenewalConsent?: boolean;
    name: string;
    email: string;
    stripe: Stripe;
    clientSecret?: string;
    onError: (errors: StripeCardDetails) => void;
  }) => Promise<void>;
  paymentData?: PaymentPayload[];
  clientSecret?: string;
} & {
  showAutoRenew?: boolean;
};

const CheckoutForm: React.FC<StripeCheckoutFormProps> = ({
  customerEmail,
  onSubmit,
  onCancel,
  paymentData,
  clientSecret,
  showAutoRenew = false,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const { t } = useT();
  const isMobile = useMediaQuery("(max-width:480px)");
  const productIdentifier = useSelector(
    (state: RootState) =>
      state.client.availableProducts[0]?.product?.product_identifier
  );

  const customStyles = useSelector(customStylesSelector);
  const { purchase } = useSelector(paymentSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [isPaymentFailed, setIsPaymentFailed] = useState(false);
  const [isFormReady, setIsFormReady] = useState(false);
  const [stripeError, setStripeError] = useState<StripeCardDetails>({
    cardNumber: "",
    cardExpiry: "",
    cardCvc: "",
  });
  const getInitialValues = () => ({
    email: customerEmail ?? "",
    name: "",
    ...(showAutoRenew &&
      purchase && {
      autoRenewalConsent: purchase?.auto_renewal_consent,
    }),
  });
  const [initialValues, setInitialValues] = useState(getInitialValues());

  useEffect(() => {
    if (purchase) {
      setInitialValues((prevValues) => ({
        ...prevValues,
        autoRenewalConsent: purchase.auto_renewal_consent ?? false,
      }));
    }
  }, [purchase]);

  useEffect(() => {
    const overlayLoader = document.getElementById("overlayLoader");
    if (isLoading) overlayLoader?.style?.setProperty("display", "flex");
    else overlayLoader?.style?.setProperty("display", "none");
  }, [isLoading]);

  const StripeElementOptions: StripeCardElementOptions = {
    style: {
      base: {
        lineHeight: "3.8",
        color: customStyles.muiTheme.palette.text.secondary,
      },
    },
  };

  const onStripeInputChange = (
    event: StripeElementChangeEvent,
    key: string
  ) => {
    if (event?.error?.message) {
      setStripeError((stripeErr) => ({
        ...stripeErr,
        [key]: event?.error?.message,
      }));
    } else {
      setStripeError((stripeErr) => ({
        ...stripeErr,
        [key]: "",
      }));
    }
  };

  const checkStripeElementValid = (key) => {
    const cardElement = document.getElementById(key);
    let isValid = true;

    if (cardElement) {
      if (cardElement.classList.contains("StripeElement--empty")) {
        setStripeError((stripeErr) => ({
          ...stripeErr,
          [key]: "Your card number is incomplete.",
        }));
        isValid = false;
      } else if (cardElement.classList.contains("StripeElement--invalid")) {
        setStripeError((stripeErr) => ({
          ...stripeErr,
          [key]: "Your card number is invalid.",
        }));
        isValid = false;
      }
    }
    return isValid;
  };

  const checkStripeElementsValid = () => {
    let isValid = false;
    isValid = checkStripeElementValid("cardNumber");
    isValid = checkStripeElementValid("cardExpiry");
    isValid = checkStripeElementValid("cardCvc");

    return isValid;
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Your email is invalid.")
      .required("Your email is incomplete."),
    name: Yup.string().required("Your name is incomplete."),
  });

  const _onSubmit = async (values: any) => {
    try {
      if (
        !stripe ||
        !elements ||
        stripeError.cardNumber ||
        stripeError.cardExpiry ||
        stripeError.cardCvc
      ) {
        return;
      }
      dispatch(setLoadingValue(true));
      dispatch(setLoaderText(t("PROCESSING_PAYMENT")));

      setIsLoading(true);
      const paymentMethod = await stripe?.createPaymentMethod({
        type: "card",
        card: elements.getElement("cardNumber"),
        billing_details: {
          name: values.name,
          email: values.email,
        },
      });

      await onSubmit({
        paymentMethodId: paymentMethod?.paymentMethod?.id,
        name: values.name,
        email: values.email,
        autoRenewalConsent: values.autoRenewalConsent,
        stripe,
        clientSecret,
        onError: (errors) =>
          setStripeError((_error) => ({
            ..._error,
            ...errors,
          })),
      });
    } catch (error) {
      setIsPaymentFailed(true);
      console.error(error);
    } finally {
      setIsLoading(false);
      dispatch(setLoadingValue(false));
      dispatch(setLoaderText(null));
    }
  };

  const InputLabelStyle = {
    color: customStyles.input.color,
    fontSize: "14px",
    fontWeight: "500",
    mb: "10px",
    fontFamily: customStyles.muiTheme.typography.fontFamily,
  };

  const StripeElementWrapperStyle = {
    border: `1px solid ${customStyles?.input?.borderColor ?? "#a8a8a8"}`,
    borderRadius: "6px",
    padding: "0px 16px",
    background: customStyles.input.backgroundColor,
    "&:hover": {
      border: `1px solid ${customStyles?.input?.hover?.borderColor}`,
    },
  };

  const StripeErrorStyle = {
    margin: "0.25rem 0 0 0",
    color: "#df1b41",
    fontSize: "0.93rem",
    fontFamily: customStyles.muiTheme.typography.fontFamily,
    fontWeight: 500,
  };

  const TextFieldStyle = {
    ".MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${customStyles?.input?.borderColor ?? "#a8a8a8"}`,
      "&:hover": {
        border: `1px solid ${customStyles?.input?.hover?.borderColor}`,
      },
    },
    ".MuiInputBase-root": {
      height: "54px",
      borderRadius: "6px",
      fontWeight: "normal",
      color: customStyles.input.color,
      backgroundColor: customStyles.input.backgroundColor,
    },
    "&.Mui-focused": {
      // outline: "none",
      // border: "none",
    },
    ".Mui-error": StripeErrorStyle,
  };

  const StripeErrorMessage = ({ elementKey }) =>
    stripeError[elementKey] ? (
      <Typography sx={StripeErrorStyle}>{stripeError[elementKey]}</Typography>
    ) : null;

  const onFocus = (event: { elementType: StripeElementType }) => {
    const parentEle = document?.getElementById(
      event?.elementType
    )?.parentElement;
    if (parentEle) {
      parentEle.style.border = `2px solid ${stripeError[event.elementType]
          ? "red"
          : customStyles?.input?.active?.borderColor
        }`;
    }
  };

  const onBlur = (event: { elementType: StripeElementType }) => {
    const parentEle = document?.getElementById(
      event?.elementType
    )?.parentElement;
    if (parentEle) {
      parentEle.style.border = `1px solid ${stripeError[event.elementType]
          ? "red"
          : customStyles?.input?.borderColor ?? "#a8a8a8"
        }`;
    }
  };

  if (isPaymentFailed) {
    return (
      <Box
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        py={4}
      >
        <div>
          <CancelOutlined fontSize="large" color="error" />
        </div>
        <Typography color={"error"} variant="h6">
          {t("PAYMENT_PAGE_FAILURE_MESSAGE")}
        </Typography>
        <AnimatedButton
          variant="text"
          onClick={() => setIsPaymentFailed(false)}
        >
          <Typography
            sx={{
              fontSize: "14px",
              color: customStyles?.button?.backgroundColor,
            }}
          >
            {t("PAYMENT_PAGE_RETRY_PAYMENT")}
          </Typography>
        </AnimatedButton>
      </Box>
    );
  }

  return (
    <>
      {paymentData && productIdentifier === "JWL" && COUNTRY === "us" && (
        <Box my={"8px"}>
          <StripeWalletPayments
            paymentData={paymentData}
            setIsLoading={setIsLoading}
            setIsPaymentFailed={setIsPaymentFailed}
          />
        </Box>
      )}
      <Formik
        initialValues={initialValues}
        validateOnMount={true}
        validationSchema={validationSchema}
        onSubmit={_onSubmit}
        enableReinitialize={true}
      >
        {({ handleSubmit }) => {
          const onFormSubmit = (e: FormEvent<HTMLFormElement>) => {
            checkStripeElementsValid();
            handleSubmit(e);
          };

          return (
            <Form id="payment-form" onSubmit={onFormSubmit}>
              <Box sx={{ marginBottom: "0.75rem" }}>
                <InputLabel sx={InputLabelStyle}>
                  {t("CHECKOUT_FORM_NAME_ON_CARD")}
                </InputLabel>
                <Field
                  placeholder={t("CHECKOUT_FORM_NAME_ON_CARD")}
                  fullWidth
                  data-cy="name-on-card"
                  sx={TextFieldStyle}
                  component={TextField}
                  name="name"
                />
              </Box>
              <Box sx={{ marginBottom: "0.75rem" }}>
                <InputLabel sx={InputLabelStyle}>
                  {t("CHECKOUT_FORM_CARD_NUMBER")}
                </InputLabel>
                <Box
                  sx={{
                    ...StripeElementWrapperStyle,
                    borderColor: stripeError["cardNumber"] ? "#df1b41" : "",
                  }}
                >
                  <CardNumberElement
                    onFocus={onFocus}
                    onBlur={onBlur}
                    id="cardNumber"
                    onChange={(event) =>
                      onStripeInputChange(event, "cardNumber")
                    }
                    onReady={() => setIsFormReady(true)}
                    options={StripeElementOptions}
                  />
                </Box>
                <StripeErrorMessage elementKey={"cardNumber"} />
              </Box>
              <Grid container spacing={2}>
                <Grid item md={6} sm={12} xs={12}>
                  <Box sx={{ marginBottom: "0.75rem" }}>
                    <InputLabel sx={InputLabelStyle}>
                      {t("CHECKOUT_FORM_CARD_EXPIRY")}
                    </InputLabel>
                    <Box
                      sx={{
                        ...StripeElementWrapperStyle,
                        borderColor: stripeError["cardExpiry"] ? "#df1b41" : "",
                      }}
                    >
                      <CardExpiryElement
                        id="cardExpiry"
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onChange={(event) =>
                          onStripeInputChange(event, "cardExpiry")
                        }
                        options={StripeElementOptions}
                      />
                    </Box>
                    <StripeErrorMessage elementKey={"cardExpiry"} />
                  </Box>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Box sx={{ marginBottom: "0.75rem" }}>
                    <InputLabel sx={InputLabelStyle}>
                      {t("CHECKOUT_FORM_CVV")}
                    </InputLabel>
                    <Box
                      sx={{
                        ...StripeElementWrapperStyle,
                        borderColor: stripeError["cardCvc"] ? "#df1b41" : "",
                      }}
                    >
                      <CardCvcElement
                        id="cardCvc"
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onChange={(event) =>
                          onStripeInputChange(event, "cardCvc")
                        }
                        options={StripeElementOptions}
                      />
                    </Box>
                    <StripeErrorMessage elementKey={"cardCvc"} />
                  </Box>
                </Grid>
              </Grid>
              <Box sx={{ marginBottom: "0.75rem" }}>
                <InputLabel sx={InputLabelStyle}>
                  {t("CHECKOUT_FORM_EMAIL")}
                </InputLabel>
                <Field
                  placeholder={t("CHECKOUT_FORM_EMAIL")}
                  fullWidth
                  data-cy="email-on-card"
                  sx={TextFieldStyle}
                  component={TextField}
                  name="email"
                />
              </Box>

              {showAutoRenew && (
                <Box sx={{ marginBottom: "0.75rem" }}>
                  <FormControlLabel
                    control={
                      <Field name="autoRenewalConsent">
                        {({ field, form: { setFieldValue } }) => (
                          <Switch
                            checked={!!field.value}
                            onChange={(event) => {
                              setFieldValue(field.name, event.target.checked);
                              dispatch(toggleAutoRenewalConsent(event.target.checked));
                            }}
                            color="primary"
                          />
                        )}
                      </Field>
                    }
                    label={
                      <Field name="autoRenewalConsent">
                        {({ field }) =>
                          `${t("CHECKOUT_FORM_AUTO_RENEWAL_CONSENT")} ${field.value ? "on" : "off"
                          }`
                        }
                      </Field>
                    }
                  />
                </Box>
              )}
              <footer
                style={
                  isMobile
                    ? {
                      position: "fixed",
                      bottom: "0px",
                      right: "0",
                      background: "white",
                      width: "100%",
                      padding: "20px",
                      boxShadow: isMobile && `0px 5px 5px -5px grey inset`,
                    }
                    : {
                      marginTop: "40px",
                    }
                }
              >
                <PaymentFooter
                  backButtonProps={{
                    disabled: isLoading || !stripe || !elements || !isFormReady,
                    onClickCB: onCancel,
                    btnText: t("BACK"),
                  }}
                  submitButtonProps={{
                    disabled: isLoading || !stripe || !elements || !isFormReady,
                    onClickCB: onFormSubmit,
                    btnText: t("PAYMENT_PAGE_MAKE_PAYMENT"),
                  }}
                  isLoading={isLoading}
                />
              </footer>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default CheckoutForm;
